<template>
    <li class="modal-group-item list-group-item d-flex flex-row px-2"
        v-bind:class="{ isFull }">
        <div class="d-flex justify-content-center align-items-center pl-2 py-2 pr-3">
            <img class="rounded-circle"
                 v-bind:alt="chat.name"
                 v-bind:src="`${ apiUrl }/${ apiVersion }/chat/${ chat.vendorId }/avatar`">
        </div>
        <div class="flex-grow-1 d-flex flex-column justify-content-center">
            <h5>{{ chat.name }}</h5>
            <p class="mb-0">
                {{ chat.members.length }} members ({{ chatGroupUtilRate }} full)
                <span class="badge badge-light border"
                      v-if="isFull === true">
                    Full
                </span>
            </p>
        </div>
        <div class="d-flex flex-column justify-content-center">
            <button class="btn btn-sm btn-block btn-outline-success shadow-sm px-4"
                    v-if="isInChat === false && isFull === false"
                    v-bind:class="{ 'btn-loading': isLoading }"
                    v-on:click="isReauthenticateModalOpened = true; flag = 'add'; ">
                🤝 Add
            </button>
            <button class="btn btn-sm btn-block btn-danger shadow-sm px-4"
                    v-if="isInChat === true"
                    v-bind:class="{ 'btn-loading': isLoading }"
                    v-on:click="isReauthenticateModalOpened = true; flag = 'kick'; ">
                🦵 Kick
            </button>
            <button class="btn btn-sm btn-block btn-dark shadow-sm px-4"
                    v-if="!isInChat === true && isFull === true">
                🈵 Full
            </button>
        </div>

        <modal-re-authenticate challenge-type="password"
                               v-if="adminRole === 'admin'"
                               v-bind:email="adminEmail"
                               v-bind:callback="doAction"
                               v-model="isReauthenticateModalOpened">
            <p>You are conducting a sensitive action. Please authenticate yourself. </p>
        </modal-re-authenticate>
    </li>
</template>

<script>
import { apiUrl, apiVersion, } from "@/config";
import addToGroup from "../../../api/whatsapp/addToGroup";
import kickFromGroup from "../../../api/whatsapp/kickFromGroup";
import ModalReAuthenticate from "../../modals/ModalReAuthenticate";
export default {
    name: "GroupItem",
    components: {
        ModalReAuthenticate,
    },
    props: {
        "chat": {
            type: Object,
            required: true,
        },
        "account": {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            apiUrl, apiVersion,
            isLoading: false,
            flag: "add", // "add", "kick"
            isReauthenticateModalOpened: false,
        };
    },
    computed: {
        adminRole () {
            return this.$store.state.admin.user.role;
        },

        adminEmail () {
            return this.$store.state.admin.user.email;
        },

        isFull () {
            return this.chat?.members.length >= 256;
        },

        inChatIds () {
            return Array.isArray(this.account?.chats) ?
                this.account
                    .chats
                    .map(({ _id }) => _id) :
                [];
        },

        isInChat () {
            return this.inChatIds
                .includes(this.chat?._id);
        },

        chatGroupUtilRate () {
            return `${ (this.chat?.members.length / 256 * 100).toFixed(2) }%` ?? "TBD";
        },
    },
    methods: {
        doAction () {
            if (this.flag === "add") {
                this.addToGroup();
                return;
            }
            if (this.flag === "kick") {
                this.kickFromGroup();
                return;
            }
        },

        async addToGroup () {
            this.isLoading = true;
            try {
                await addToGroup(
                    this.chat.vendorId,
                    this.account.vendorId,
                );
            } catch (e) {
                console.error(e);
                this.isLoading = false;
                throw e;
            }
            await new Promise(
                resolve => setTimeout(() => resolve(), 5000)
            );
            await this.$store.dispatch(
                "whatsapp/loadAccount",
                `${ this.account.phone }`
            );
            this.isLoading = false;
        },

        async kickFromGroup () {
            this.isLoading = true;
            try {
                await kickFromGroup(
                    this.chat.vendorId,
                    this.account.vendorId,
                );
            } catch (e) {
                console.error(e);
                this.isLoading = false;
                throw e;
            }
            await new Promise(
                resolve => setTimeout(() => resolve(), 5000)
            );
            await this.$store.dispatch(
                "whatsapp/loadAccount",
                `${ this.account.phone }`
            );
            this.isLoading = false;
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-group-item {
    .rounded-circle {
        width: 64px;
        height: 64px;
        object-fit: cover;
    }

    &.isFull {
        filter: grayscale(1);
    }
}
</style>
