import localforage from "localforage";

import {
    apiUrl as rootUrl,
    apiVersion
} from "../../config";

export async function addToGroup (
    chatId,
    memberId
) {
    const token = await localforage.getItem("nakamian.admin-token");
    const response = await fetch(
        `${ rootUrl }/${ apiVersion }/chat/${ chatId }/member`,
        {
            "method": "POST",
            "mode": "cors",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
            "body": JSON.stringify(
                {
                    "members": [memberId]
                }
            ),
        }
    );

    if (response.ok) {
        return true;
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default addToGroup;
